.root {
  display: grid;
  grid-template-columns: 2fr 2fr minmax(auto, 400px) 2fr 2fr 1fr;
  grid-template-rows: 100px auto;
  height: 100%;
}

.header {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
}

.noExtension {
  grid-column: 2/6;
  grid-row-start: 1;
  grid-row-end: 2;
  z-index: 10;
}

.header > * {
  margin-right: 2rem;
}

.userInfo {
  grid-column: 7/-1;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.userInfo > * {
  margin-right: 2rem;
}

.righto {
  grid-column: 4 / 7;
  grid-row: 2;
  z-index: 1;
}

.righto.selected {
  z-index: 9;
  background: white;
}

.area {
  grid-column: 2 /6;
  grid-row: 2;
  z-index: 9;
  overflow: auto;
  scrollbar-width: thin;
}

.area::-webkit-scrollbar {
  background-color: blueviolet;
  width: 5px;
}

.area::-webkit-scrollbar-track {
  background: white;
  border-radius: 3px;
}

.area::-webkit-scrollbar-thumb {
  background: blueviolet;
}

area::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.nav {
  grid-column: 1;
  grid-row: 2;
}

.nav > * {
  margin: 2rem;
}

.areagridcontent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.areagridcontent sl-card {
  margin-right: 3px;
}

.cont {
  height: 300px;
}

.card-overview {
  max-width: 300px;
}

.card-overview small {
  color: var(--sl-color-neutral-500);
}

.card-overview strong {
  color: var(--sl-color-neutral-900);
}

.card-overview [slot="footer"] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-content {
  height: 200px;
}

.thread-start {
  display: block;
}

.threadDisplayAction {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.visibleComments {
  background: white;
  z-index: 9999;
  margin: 0.5rem;
}

.inner-visible-comments {
  margin: 1.5rem;
}

.comments-skeleton-paragraphs sl-skeleton {
  margin-bottom: 1rem;
}

.comments-skeleton-paragraphs sl-skeleton:nth-child(2) {
  width: 95%;
}

.comments-skeleton-paragraphs sl-skeleton:nth-child(4) {
  width: 90%;
}

.comments-skeleton-paragraphs sl-skeleton:last-child {
  width: 50%;
}

.closeButton {
  cursor: pointer;
  color: var(--sl-color-primary-600);
}

.closeButton:hover {
  color: var(--sl-color-primary-500);
}

.alertMessages {
  margin-top: 2rem;
}

.inviteText {
  margin-top: 1rem;
  color: var(--sl-color-primary-400);
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding-inline-start: 0px;
}

.react-autosuggest__input {
  width: 300px;
  padding: 10px 10px;
  border: 1px solid #aaa;
  border-radius: 2px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--focused {
  background-color: #ddd;
}

.threadHighlighted {
  padding: 10px;
  font-size: 12px;
}

.oneComment {
  margin-bottom: 5px;
  font-size: 14px;
  background: var(--sl-color-gray-100);
  padding: 5px;
}

.oneComment .author {
  font-size: 10px;
  font-style: italic;
  margin-top: 10px;
}

.nav div {
  padding-left: 1em;
}

.logo img {
  margin-left: 0.5em;
  max-height: 3rem;
}

.nav div:hover {
  background: var(--sl-color-gray-100);
  color: #0284c7;
}

.nav a {
  text-decoration: none;
}

.menuSelected {
  background: var(--sl-color-gray-200);
}

a {
  color: var(--sl-color-blue-500);
  text-decoration: none;
}

a:visited {
  color: var(--sl-color-blue-500);
}

a:hover {
  color: var(--sl-color-blue-700);
}

.nav .menuSelected a {
  color: var(--sl-color-blue-800);
}

.noResults {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  color: var(--sl-color-neutral-800);
}

.nd-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--sl-color-gray-200);
  display:flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.nd-modal{
  background: white;
  padding: 20px;
  width: 400px;
}

.nd-title{
  text-align:center;
  margin: 10px;
  color: var(--sl-color-gray-800);
}

.plain-password{
  width: 350px;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-color: var(--sl-color-neutral-300);
  border-style: solid;
  border-width: 1px;
}

@media (max-width: 800px) {
  .nav {
    grid-column: 1/7;
    grid-row: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .nav div {
    padding-left: 0px;
  }

  .nav > * {
    margin: 2rem;
  }

  .root {
    grid-template-rows: 100px auto 50px;
    grid-template-columns: 1fr 1fr;
  }

  .area {
    grid-column: 1/7;
  }

  .card-overview {
    max-width: 100%;
  }

  .righto {
    grid-column: 1/7;
  }
  .noExtension {
    grid-column: 2/2;
  }
}

.subscriptionSection {
  margin-top: 2em;
}

.subscriptionTitle {
  margin-bottom: 1em;
  font-weight: 600;
}

.subscriptionTitleThin {
  margin-bottom: 1em;
}

.subscriptionCards {
  display: flex;
  justify-content: flex-start;

  align-items: start;
}

.subscriptionCards > * {
  margin-right: 2em;
}
